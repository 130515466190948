import axios from 'axios';
import { fuelheaders } from 'state/logged-user/actions';
import { SITE_SERVER } from './misc';

if (process.env.NODE_ENV !== 'development') {
  axios.defaults.withCredentials = true;
}

export const getAddressBook = (
  page, limit, name, carrierCode, countryCode, state, city,
  sortColumn, sortType,
) => axios({
  method: 'GET',
  url: `${SITE_SERVER}/order_recipients?page=${page}&limit=${limit}${name !== null ? `&recipient_name=${name}` : ''}${carrierCode !== null ? `&carrier_code=${carrierCode}` : ''}${countryCode !== null ? `&shipping_country_code=${countryCode}` : ''}${state !== null ? `&shipping_state=${state}` : ''}${city !== null ? `&shipping_city=${city}` : ''}${sortColumn !== null ? `&sort_column=${sortColumn}` : ''}${sortType !== null ? `&sort_type=${sortType}` : ''}`,
  headers: fuelheaders(),
});

export const requestAddressBookDetails = id => axios({
  method: 'GET',
  url: `${SITE_SERVER}/order_recipients/${id}`,
  headers: fuelheaders(),
});

export const postAddressBook = (dataToSubmit, id) => axios({
  method: id !== null ? 'PUT' : 'POST',
  url: `${SITE_SERVER}/order_recipients${id !== null ? `/${id}` : ''}`,
  headers: fuelheaders(),
  data: dataToSubmit,
});

export const deleteAddressBook = id => axios({
  method: 'DELETE',
  url: `${SITE_SERVER}/order_recipients/${id}`,
  headers: fuelheaders(),
});
