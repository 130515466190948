import axios from 'axios';
import { fuelheaders } from 'state/logged-user/actions';
import { SITE_SERVER } from './misc';

if (process.env.NODE_ENV !== 'development') {
  axios.defaults.withCredentials = true;
}

export const getCalendarEvents = (type, day, month, year) => axios({
  method: 'GET',
  url: `${SITE_SERVER}/calendar_events?type=${type}${day !== null && typeof day !== 'undefined' ? `&day=${day}` : ''}${month !== null && typeof month !== 'undefined' ? `&month=${month}` : ''}${year !== null && typeof year !== 'undefined' ? `&year=${year}` : ''}`,
  headers: fuelheaders(),
});

export const getCalendarEventsNotif = () => axios({
  method: 'GET',
  url: `${SITE_SERVER}/calendar_events_notifications`,
  headers: fuelheaders(),
});

export const resetCalendarEventsNotif = () => axios({
  method: 'GET',
  url: `${SITE_SERVER}/calendar_events_notifications?reset_count`,
  headers: fuelheaders(),
});

export const refreshApi = () => axios({
  method: 'GET',
  url: `${SITE_SERVER}/refreshtoken`,
  headers: fuelheaders(),
});

export const getTimeZone = () => axios({
  method: 'GET',
  url: `${SITE_SERVER}/users/settings/timezones`,
  headers: fuelheaders(),
});

export const updateTimeZone = timezonedata => axios({
  method: 'PUT',
  url: `${SITE_SERVER}/users/settings/timezones`,
  headers: fuelheaders(),
  data: timezonedata,
});
