import { createSelector } from 'reselect';

export const getModalDrawerState = state => state.modal;

export const showModal = createSelector([getModalDrawerState], modal => modal.Modal);

export const showAlertModal = createSelector([getModalDrawerState], modal => modal.AlertModal);

export const showDrawer = createSelector([getModalDrawerState], modal => modal.Drawer);

export const showPage = createSelector([getModalDrawerState], modal => modal.Page);

export const reloadComponent = createSelector([getModalDrawerState], modal => modal.Component);
