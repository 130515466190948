import {
  SET_CALENDAR_EVENTS_DATA,
  SET_CALENDAR_EVENTS_NOTIF_DATA,
  SET_TIMEZONE_DATA,
} from './types';

const initialState = {
  calevents: [],
  caleventsnotif: {},
  timezones: {},
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_CALENDAR_EVENTS_DATA: {
      return { ...state, calevents: action.payload.calevents };
    }
    case SET_CALENDAR_EVENTS_NOTIF_DATA: {
      return { ...state, caleventsnotif: action.payload.caleventsnotif };
    }
    case SET_TIMEZONE_DATA: {
      return { ...state, timezones: action.payload.timezones };
    }
    default: return state;
  }
};

export default reducer;
