import Cookies from 'universal-cookie';
import { isEmpty } from '@entando/utils';

import { SET_USER, UNSET_USER, SET_HEADER_STATUS, SET_SETTINGS_DATA, SET_EXTRA_DATA, SET_CLIENT_DATA } from './types';

const cookies = new Cookies();

const getInitialState = () => ({
  username: cookies.get('username'),
  token: cookies.get('token'),
  data: cookies.get('data'),
  cusettings: cookies.get('cusettings'),
  headerStatus: cookies.get('headerStatus'),
  settings: JSON.parse(localStorage.getItem('settings')),
  extradata: [],
  client: {},
});

const isPayloadValid = payload => (
  !isEmpty(payload.username) &&
  !isEmpty(payload.token) &&
  !isEmpty(payload.data) &&
  typeof payload.username === 'string' &&
  typeof payload.token === 'string' &&
  typeof payload.data === 'string'
);

const reducer = (state = getInitialState(), action = {}) => {
  switch (action.type) {
    case SET_USER: {
      return isPayloadValid(action.payload.user) ? action.payload.user : state;
    }
    case UNSET_USER: {
      return action.payload.user;
    }
    case SET_HEADER_STATUS: {
      return { ...state, headerStatus: action.payload.headerStatus };
    }
    case SET_SETTINGS_DATA: {
      return { ...state, settings: action.payload.settings };
    }
    case SET_EXTRA_DATA: {
      return { ...state, extradata: action.payload.extradata };
    }
    case SET_CLIENT_DATA: {
      return { ...state, client: action.payload.client };
    }
    default: return state;
  }
};

export default reducer;
