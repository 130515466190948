import axios from 'axios';
import { fuelheaders } from 'state/logged-user/actions';
import { SITE_SERVER } from './misc';

if (process.env.NODE_ENV !== 'development') {
  axios.defaults.withCredentials = true;
}

export const notifList = (limit, page) => axios({
  method: 'GET',
  url: `${SITE_SERVER}/notifications?limit=${limit}&page=${page}`,
  headers: fuelheaders(),
});

export const notifRead = (id, dataToSubmit = {}) => axios({
  method: 'POST',
  url: `${SITE_SERVER}/notifications/read/${id}`,
  headers: fuelheaders(),
  data: dataToSubmit,
});

export const notifListAdmin = () => axios({
  method: 'GET',
  url: `${SITE_SERVER}/admin/notifications`,
  headers: fuelheaders(),
});

export const notifUnread = () => axios({
  method: 'GET',
  url: `${SITE_SERVER}/notifications/total_unread`,
  headers: fuelheaders(),
});

export const notificationSettings = () => axios({
  method: 'GET',
  url: `${SITE_SERVER}/users/notification_settings`,
  headers: fuelheaders(),
});

export const updateNotifSettings = dataToSubmit => axios({
  method: 'PUT',
  url: `${SITE_SERVER}/users/notification_settings`,
  headers: fuelheaders(),
  data: dataToSubmit,
});

export const resetNotification = () => axios({
  method: 'POST',
  url: `${SITE_SERVER}/notifications/reset_count`,
  headers: fuelheaders(),
});
