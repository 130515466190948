import { updateUser } from 'api/staff';
import Cookies from 'universal-cookie';
import { showLoading, hideLoading, showActionLoading, hideActionLoading, setLoadType } from 'state/loading/actions';
import responseCheker from 'api/responseCheker';
import getUserProfile from 'api/userProfile';
import { SET_USER, UNSET_USER, SET_HEADER_STATUS, SET_SETTINGS_DATA, SET_EXTRA_DATA, SET_CLIENT_DATA } from './types';

const cookies = new Cookies('fuelCookie');

export const fuelheaders = () => {
  const cookiedata = cookies.get('token');
  const hdata = {
    'Content-Type': 'application/json',
    Accept: 'application/vnd.api+json',
    Authorization: `Bearer ${cookiedata}`,
  };
  return hdata;
};

export const fuelmultiheaders = () => {
  const cookiedata = cookies.get('token');
  const hdata = {
    'Content-Type': 'multipart/form-data',
    Accept: 'application/vnd.api+json',
    Authorization: `Bearer ${cookiedata}`,
  };
  return hdata;
};

export const setUser = user => ({
  type: SET_USER,
  payload: {
    user,
  },
});

export const unsetUser = () => ({
  type: UNSET_USER,
  payload: {
    user: {
      username: null,
      token: null,
      data: null,
    },
  },
});

export const setHeaderStatus = headerStatus => ({
  type: SET_HEADER_STATUS,
  payload: {
    headerStatus,
  },
});

export const setSettings = settings => ({
  type: SET_SETTINGS_DATA,
  payload: { settings },
});

export const loginUser = (username, token, data) => (dispatch) => {
  dispatch(setUser({
    username,
    token,
    data,
    headerStatus: '',
  }));
  const { customer, customer_user, role } = data; // eslint-disable-line
  cookies.set('cusettings', JSON.stringify(customer_user.settings), { path: '/' });
  customer_user.settings = null;
  const datas = { customer, customer_user, role };
  cookies.set('data', JSON.stringify(datas), { path: '/' });
  cookies.set('username', username, { path: '/' });
  cookies.set('token', token, { path: '/' });
  cookies.set('headerStatus', '', { path: '/' });
};

export const logoutUser = () => (dispatch) => {
  dispatch(unsetUser());

  cookies.remove('username');
  cookies.remove('token');
  cookies.remove('data');
  cookies.remove('cusettings');
  localStorage.clear();
};

export const token = () => cookies.get('token');

export const getUsername = () => cookies.get('username');

export const setToken = tokendata => (dispatch) => {
  cookies.set('token', tokendata, { path: '/' });
  dispatch(setHeaderStatus(''));
};

export const setHeader = status => (dispatch) => {
  cookies.set('headerStatus', status, { path: '/' });
  dispatch(setHeaderStatus(status));
};

export const setUserData = data => (dispatch) => {
  dispatch(showLoading());
  dispatch(setUser({
    data,
  }));
  cookies.set('data', JSON.stringify(data), { path: '/' });
  dispatch(hideLoading());
};

export const setClient = client => ({
  type: SET_CLIENT_DATA,
  payload: { client },
});

export const successResponse = (response, type) => (dispatch) => {
  dispatch(responseCheker(response));
  if (response) {
    if (type === 'client') {
      if (response.status !== 200) {
        dispatch(setLoadType('logout'));
      } else {
        dispatch(setClient(response.data.attributes));
      }
    }
  }
  dispatch(hideActionLoading());
};

export const errorResponse = err => (dispatch) => {
  const result = dispatch(responseCheker(err.response));
  if (result === 'error') {
    // error here
  }
};

export const setUserSettings = (uid, data) => (dispatch) => {
  localStorage.setItem('settings', JSON.stringify(data));
  dispatch(setSettings(data));
  updateUser(uid, { settings: JSON.stringify(data) }).then(
    response => dispatch(successResponse(response)),
    err => dispatch(errorResponse(err)),
  );
};

export const getClient = () => (dispatch) => {
  dispatch(showActionLoading());
  getUserProfile().then(
    response => dispatch(successResponse(response, 'client')),
    err => dispatch(errorResponse(err)),
  );
};

export const setExtraData = extradata => ({
  type: SET_EXTRA_DATA,
  payload: { extradata },
});
