import { setToken, setHeader } from 'state/logged-user/actions';

const responseCheker = response => (dispatch) => {
  if (typeof response === 'undefined') {
    return 'server error';
  }
  if (response.status < 400) {
    if (typeof response.headers.authorization !== 'undefined') {
      const auth = response.headers.authorization.trim();
      const x = auth.split(' ');
      if (x.length === 2) {
        const htoken = x[1].trim();
        dispatch(setToken(htoken));
      }
    }
    return 'pass';
  }
  if (response.status === 500) {
    /* console.log(response); // eslint-disable-line */
    if (response.data.message === 'The token has been blacklisted') {
      dispatch(setHeader('expired'));
      return 'expired';
    }
    if (response.data.exception === 'TypeError') {
      return response.data.exception;
    }
  }
  if (response.status === 401 || response.status === 400) {
    if (response.data.errors[0].detail === 'Token has expired'
        || response.data.errors[0].detail === 'The token has been blacklisted'
        || response.data.errors[0].detail === 'Wrong number of segments'
        || response.data.errors[0].detail === 'Token Signature could not be verified.') {
      dispatch(setHeader('expired'));
      return 'expired';
    }
  }
  return 'error';
};

export default responseCheker;
