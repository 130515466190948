import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Layout from 'ui/hoc/Layout';
import RoutesContainer from 'ui/route/RoutesContainer';
import withTheme from 'ui/withTheme';

class App extends Component {
  render() {
    return (
      <Router>
        <Layout {...this.props}>
          <RoutesContainer />
        </Layout>
      </Router>
    );
  }
}

export default withTheme((App));
