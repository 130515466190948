import {
  SET_ROLE_LIST,
  SET_MORE_ROLE_LIST,
  SET_ROLE_DATA,
  SET_DND_ROLE_DATA,
} from './types';

const initialState = {
  list: {
    dataObj: {},
    dataArr: [],
  },
  role: {},
  dndrole: {
    canEdit: [],
    viewOnly: [],
    noAccess: [],
  },
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_ROLE_LIST: {
      return Object.assign({}, state, {
        list: {
          dataObj: action.payload.rolelist,
          dataArr: action.payload.rolelist.data,
        },
      });
    }
    case SET_MORE_ROLE_LIST: {
      const dataArr = state.list.dataArr.concat(action.payload.rolelist.data);
      return Object.assign({}, state, {
        list: {
          dataObj: action.payload.rolelist,
          dataArr,
        },
      });
    }
    case SET_ROLE_DATA: {
      return { ...state, role: action.payload.role };
    }
    case SET_DND_ROLE_DATA: {
      return Object.assign({}, state, {
        dndrole: {
          canEdit: action.payload.dndrole.canEdit,
          noAccess: action.payload.dndrole.noAccess,
        },
      });
    }
    /* case SET_DND_ROLE_DATA: {
      return Object.assign({}, state, {
        dndrole: {
          canEdit: action.payload.dndrole.canEdit,
          viewOnly: action.payload.dndrole.viewOnly,
          noAccess: action.payload.dndrole.noAccess,
        },
      });
    } */
    default: return state;
  }
};

export default reducer;
