export const SET_SALES_DATA = 'reports/set-sales-data';
export const SET_ORDERS_DATA = 'reports/set-orders-data';
export const SET_SALES_TAB_DATA = 'reports/sales-tab-data';
export const SET_STOCK_PRODUCTS_DATA = 'reports/stock-products-data';
export const SET_VIEW_REPORT_DATA = 'reports/view-report-data';
export const SET_SELLING_PRODUCTS_DATA = 'reports/selling-products-data';
export const SET_DASHBOARD_DATA = 'reports/set-dashboard-data';
export const SET_WEEKLY_PRODUCTS_DATA = 'reports/set-weekly-products-data';
export const SET_MONTHLY_PRODUCTS_DATA = 'reports/set-monthly-products-data';
export const SET_TIME_PERIOD_DATA = 'reports/set-time-period-data';
export const SET_ORDER_REPORTS_DAILY_DATA = 'reports/set-order-reports-daily-data';
export const SET_ORDER_REPORTS_WEEKLY_DATA = 'reports/set-order-reports-weekly-data';
export const SET_ORDER_REPORTS_MONTHLY_DATA = 'reports/set-order-reports-monthly-data';
export const SET_ORDER_REPORTS_MONTHLY_DASHBOARD_DATA = 'reports/set-order-reports-monthly-dashboard-data';
export const RESET_ORDER_REPORTS_MONTHLY_DASHBOARD_DATA = 'reports/reset-order-reports-monthly-dashboard-data';
export const SET_DAILY_PROPS_DATA = 'reports/set-daily-properties-data';
export const SET_PRODUCT_REPORTS_MONTHLY_DATA = 'reports/set-product-reports-monthly-data';
export const SET_PRODUCT_REPORTS_WEEKLY_DATA = 'reports/set-product-reports-weekly-data';
export const SET_PRODUCT_REPORTS_DAILY_DATA = 'reports/set-product-reports-daily-data';
export const SET_SKU_DATA = 'reports/set-sku-data';
export const SET_DAY_DATA = 'reports/set-day-data';
export const SET_COLUMN_SETTINGS = 'reports/set-column-settings';
export const SET_EXPORT_URL_DATA = 'reports/set-export-url-data';
