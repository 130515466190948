export const stripHtml = (html) => {
  const temporalDivElement = document.createElement('div');
  temporalDivElement.innerHTML = html;
  return temporalDivElement.textContent || temporalDivElement.innerText || '';
};

export const trimString = (string, length) => {
  const stripString = stripHtml(string);
  return stripString.length > length ? `${stripString.substring(0, length)}...` : stripString;
};

export const isiOS = () => {
  const ua = navigator.userAgent;
  const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
  const webkitUa = !!ua.match(/WebKit/i);
  return typeof webkitUa !== 'undefined' && iOS && webkitUa && !ua.match(/CriOS/i);
};

export const getTheme = () => 6;

export const convertToPounds = (str, hastofixed = false) => {
  const n = Number.parseFloat(str);
  const zero = 0;
  if (!str || isNaN(n) || n < 0) return zero.toFixed(2); // eslint-disable-line
  if (hastofixed) {
    return n.toFixed(2);
  }
  return n;
};

export const capitalized = (str) => {
  if (typeof str !== 'string') {
    throw Error('Feed me string');
  } else if (!str) {
    return '';
  } else {
    return str
      .split(' ')
      .map((s) => {
        if (s.length === 1) {
          return s.toUpperCase();
        }
        const firstLetter = s.split('')[0].toUpperCase();
        const restOfStr = s.substr(1, s.length).toLowerCase();
        return firstLetter + restOfStr;
      })
      .join(' ');
  }
};

export const getMonthName = (date, useDate = true, dateAcronym = false) => {
  let monthNames = [
    'January', 'February', 'March',
    'April', 'May', 'June',
    'July', 'August', 'September',
    'October', 'November', 'December',
  ];
  if (dateAcronym) {
    monthNames = [
      'Jan', 'Feb', 'Mar',
      'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sept',
      'Oct', 'Nov', 'Dec',
    ];
  }
  if (useDate) {
    return monthNames[date.getMonth()];
  }
  return monthNames[date];
};

export const getMonthNumber = (month) => {
  const monthNames = {
    January: 1,
    February: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12,
  };
  return monthNames[month];
};

export const monthAcronym = () => {
  const data = {
    January: { name: 'Jan' },
    February: { name: 'Feb' },
    March: { name: 'Mar' },
    April: { name: 'Apr' },
    May: { name: 'May' },
    June: { name: 'Jun' },
    July: { name: 'Jul' },
    August: { name: 'Aug' },
    September: { name: 'Sep' },
    October: { name: 'Oct' },
    November: { name: 'Nov' },
    December: { name: 'Dec' },
  };
  return data;
};

/* **
ISO Date = "2015-03-25"
Short Date = "03/25/2015"
Long Date = "Mar 25, 2015"
** */
export const formatDate = (d, type) => {
  const date = new Date(d.replace(/\s/, 'T'));
  const day = (`0${date.getDate()}`).slice(-2);
  const month = (`0${date.getMonth() + 1}`).slice(-2);
  const year = date.getFullYear();
  if (type === 'Long Date') {
    const monthname = date.toLocaleString('default', { month: 'short' });
    return `${monthname} ${day}, ${year}`;
  }
  if (type === 'Short Date') {
    return `${month}/${day}/${year}`;
  }
  return `${year}-${month}-${day}`;
};

export const getMaxNumber = (num) => {
  let maxnum = 5000;
  if (num < 20) {
    maxnum = 20;
  }
  if (num > 19 && num < 40) {
    maxnum = 40;
  }
  if (num > 39 && num < 60) {
    maxnum = 60;
  }
  if (num > 59 && num < 100) {
    maxnum = 100;
  }
  if (num > 99 && num < 300) {
    maxnum = 300;
  }
  if (num > 299 && num < 600) {
    maxnum = 600;
  }
  if (num > 599 && num < 800) {
    maxnum = 800;
  }
  if (num > 799 && num < 1000) {
    maxnum = 1000;
  }
  if (num > 999 && num < 1600) {
    maxnum = 1600;
  }
  if (num > 1599 && num < 2000) {
    maxnum = 2000;
  }
  if (num > 1999 && num < 3000) {
    maxnum = 3000;
  }
  if (num > 2999 && num < 4000) {
    maxnum = 4000;
  }
  if (num > maxnum) {
    maxnum += 1000;
  }
  return maxnum;
};

export const request = 'PUT';

// export const getEnv = () => (process.env.NODE_ENV === 'development' ? 'http://54.214.178.47/api' : '/api');

export const getEnv = () => (process.env.NODE_ENV === 'development' ? 'https://fuel.ipslgx.com/api' : '/api');

// export const getEnv = () => (process.env.NODE_ENV === 'development' ? 'https://fueltest.ipslgx.com/api' : '/api');

// export const getEnv = () => (process.env.NODE_ENV === 'development' ? 'http://34.215.182.127/api' : '/api');

// export const getEnv = () => (process.env.NODE_ENV === 'development' ? 'https://fuel2.fulfillmentservices.com/api' : '/api');

// SERVER ROUTES
export const SITE_SERVER = getEnv();
