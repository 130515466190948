import { createSelector } from 'reselect';

export const getNotifState = state => state.notification;

export const getUnreadNotifData = createSelector([getNotifState], notification => notification.unreadNotif); // eslint-disable-line

export const getNotifData = createSelector([getNotifState], notification => notification.list);

// eslint-disable-next-line
export const getNotifListData = createSelector([getNotifState], notification => notification.allNotifList);

// eslint-disable-next-line
export const getNotifSettings = createSelector([getNotifState], notification => notification.notifSettings);

