import {
  SET_NOTIF_DATA,
  SET_NOTIFLIST_DATA,
  SET_MORE_NOTIFLIST_DATA,
  RESET_NOTIF_DATA,
  UNREAD_NOTIF_DATA,
  SET_NOTIFSETTINGS_DATA,
} from './types';

const initialState = {
  list: {
    dataObj: {},
    dataArr: [],
  },
  allNotifList: {
    dataObj: {},
    dataArr: [],
  },
  unreadNotif: {},
  notifSettings: {
    dataObj: {},
    dataArr: [],
  },
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case UNREAD_NOTIF_DATA: {
      return Object.assign({}, state, {
        unreadNotif: action.payload.support.data,
      });
    }
    case SET_NOTIF_DATA: {
      return Object.assign({}, state, {
        list: {
          dataObj: action.payload.support,
          dataArr: action.payload.support.data,
        },
      });
    }
    case SET_NOTIFLIST_DATA: {
      return Object.assign({}, state, {
        allNotifList: {
          dataObj: action.payload.support,
          dataArr: action.payload.support.data,
        },
      });
    }
    case SET_MORE_NOTIFLIST_DATA: {
      const dataArr = state.allNotifList.dataArr.concat(action.payload.support.data);
      return Object.assign({}, state, {
        allNotifList: {
          dataObj: action.payload.support,
          dataArr,
        },
      });
    }
    case RESET_NOTIF_DATA: {
      return Object.assign({}, state, {
        list: {
          dataObj: {},
          dataArr: [],
        },
      });
    }
    case SET_NOTIFSETTINGS_DATA: {
      return Object.assign({}, state, {
        notifSettings: {
          dataObj: action.payload.settings,
          dataArr: action.payload.settings.data,
        },
      });
    }
    default: return state;
  }
};

export default reducer;
