/* eslint-disable max-len */
/* eslint-disable no-console */
import { getCalendarEvents, getCalendarEventsNotif, resetCalendarEventsNotif, refreshApi, getTimeZone, updateTimeZone } from 'api/settings';
import { showActionLoading, hideActionLoading } from 'state/loading/actions';
import responseCheker from 'api/responseCheker';
import { openAlertModal } from 'state/modal-drawer/actions';

import {
  SET_CALENDAR_EVENTS_DATA,
  SET_CALENDAR_EVENTS_NOTIF_DATA,
  SET_TIMEZONE_DATA,
} from './types';

export const setEvents = calevents => ({
  type: SET_CALENDAR_EVENTS_DATA,
  payload: { calevents },
});

export const setEventsNotif = caleventsnotif => ({
  type: SET_CALENDAR_EVENTS_NOTIF_DATA,
  payload: { caleventsnotif },
});

export const setTimezones = timezones => ({
  type: SET_TIMEZONE_DATA,
  payload: { timezones },
});

export const successResponse = (response, type) => (dispatch) => {
  dispatch(responseCheker(response));
  const cond = type;
  if (response) {
    switch (cond) {
      case 'getevents':
        dispatch(setEvents(response.data.data));
        break;
      case 'geteventsnotif':
        dispatch(setEventsNotif(response.data.data));
        break;
      case 'gettimezone':
        dispatch(setTimezones(response.data.data));
        break;
      default:
        break;
    }
  }
  dispatch(hideActionLoading());
};

export const errorResponse = err => (dispatch) => {
  const result = dispatch(responseCheker(err.response));
  if (result === 'error') {
    const modalKey = 'alert';
    const params = {
      type: 'error',
      title: '',
      description: err.response.data.errors[0].detail,
    };
    dispatch(openAlertModal({ modalKey, params }));
  }
  if (result === 'TypeError') {
    const modalKey = 'alert';
    const params = {
      type: 'error',
      title: '',
      description: 'Something went wrong. This has been reported',
    };
    dispatch(openAlertModal({ modalKey, params }));
  }
  dispatch(hideActionLoading());
};

export const fetchCalendarEvents = (type, day, month, year) => (dispatch) => {
  dispatch(showActionLoading());
  getCalendarEvents(type, day, month, year).then(
    response => dispatch(successResponse(response, 'getevents')),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchCalendarEventsNotif = () => (dispatch) => {
  dispatch(showActionLoading());
  getCalendarEventsNotif().then(
    response => dispatch(successResponse(response, 'geteventsnotif')),
    err => dispatch(errorResponse(err)),
  );
};

export const clearCalendarEventsNotif = () => (dispatch) => {
  dispatch(showActionLoading());
  resetCalendarEventsNotif().then(
    response => dispatch(successResponse(response, 'geteventsnotif')),
    err => dispatch(errorResponse(err)),
  );
};

export const apiRefresh = () => (dispatch) => {
  refreshApi().then(
    response => dispatch(successResponse(response)),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchTimezone = () => (dispatch) => {
  dispatch(showActionLoading());
  getTimeZone().then(
    response => dispatch(successResponse(response, 'gettimezone')),
    err => dispatch(errorResponse(err)),
  );
};

export const saveTimezone = dataToSubmit => (dispatch) => {
  dispatch(showActionLoading());
  updateTimeZone(dataToSubmit).then(
    response => dispatch(successResponse(response)),
    err => dispatch(errorResponse(err, false, true)),
  );
};
