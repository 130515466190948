import { getCountries, getUSZipCodeList, getUSState } from 'api/address';
import responseCheker from 'api/responseCheker';
import { openAlertModal } from 'state/modal-drawer/actions';
import { showActionLoading, hideActionLoading, showLoading } from 'state/loading/actions';
import { getAddressBook, requestAddressBookDetails, postAddressBook, deleteAddressBook } from 'api/addressbook';
import { SET_COUNTRIES, UNSET_COUNTRIES, SET_ZIP_CODE_LIST, SET_STATE_DATA, SET_ADDRESS_DATA, SET_ADDRESS_BOOK_DATA, SET_MORE_ADDRESS_BOOK_DATA, SET_ADDRESS_BOOK_DETAILS_DATA, SET_ADRESS_BOOK_OBJECT } from './types';


export const setCountries = countries => ({
  type: SET_COUNTRIES,
  payload: {
    countries,
  },
});

export const setZipCodeList = zipcitystate => ({
  type: SET_ZIP_CODE_LIST,
  payload: {
    zipcitystate,
  },
});

export const setCountryState = countryState => ({
  type: SET_STATE_DATA,
  payload: {
    countryState,
  },
});

export const setAddressData = addressData => ({
  type: SET_ADDRESS_DATA,
  payload: {
    addressData,
  },
});

export const setAddressBook = addressBookData => ({
  type: SET_ADDRESS_BOOK_DATA,
  payload: {
    addressBookData,
  },
});

export const setMoreAddressBook = addressBookData => ({
  type: SET_MORE_ADDRESS_BOOK_DATA,
  payload: {
    addressBookData,
  },
});

export const unsetCountries = () => ({
  type: UNSET_COUNTRIES,
  payload: {
    countries: null,
  },
});

export const setAddressBookDetails = addressBookDetail => ({
  type: SET_ADDRESS_BOOK_DETAILS_DATA,
  payload: { addressBookDetail },
});

export const setAddressBookObj = addressBookObj => ({
  type: SET_ADRESS_BOOK_OBJECT,
  payload: { addressBookObj },
});

export const successResponse = (response, type) => (dispatch) => {
  dispatch(responseCheker(response));
  if (response.data) {
    if (type === 'countries') {
      const c1 = response.data.data
        .filter(data => data.attributes.country_code === 'US'
            || data.attributes.country_code === 'CA'
            || data.attributes.country_code === 'MX')
        .map(country => (country))
        .reverse();
      const c2 = response.data.data
        .filter(data => data.attributes.country_code !== 'US'
            && data.attributes.country_code !== 'CA'
            && data.attributes.country_code !== 'MX')
        .map(country => (country));
      const customCountries = [...c1, ...c2];

      localStorage.setItem('countries', JSON.stringify(customCountries));
      dispatch(setCountries(customCountries));
    }
    if (type === 'zip') {
      dispatch(setZipCodeList(response.data.data));
    }
    if (type === 'state') {
      dispatch(setCountryState(response.data.data));
    }
    if (type === 'list' || type === 'slist') {
      dispatch(setAddressBook(response.data));
    }
    if (type === 'morelist') {
      dispatch(setMoreAddressBook(response.data));
    }
    if (type === 'detail') {
      dispatch(setAddressBookDetails(response.data));
    }
  }
  dispatch(hideActionLoading());
};

export const errorResponse = err => (dispatch) => {
  const result = dispatch(responseCheker(err.response));
  if (result === 'error') {
    const modalKey = 'alert';
    const params = {
      type: 'error',
      title: '',
      description: err.response.data.errors[0].detail,
    };
    dispatch(openAlertModal({ modalKey, params }));
  }
  if (result === 'TypeError') {
    const modalKey = 'alert';
    const params = {
      type: 'error',
      title: '',
      description: 'Something went wrong. This has been reported',
    };
    dispatch(openAlertModal({ modalKey, params }));
  }
  dispatch(hideActionLoading());
};

export const fetchCountries = () => (dispatch) => {
  dispatch(showActionLoading());
  getCountries().then(
    response => dispatch(successResponse(response, 'countries')),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchCountryState = () => (dispatch) => {
  dispatch(showActionLoading());
  getUSState().then(
    response => dispatch(successResponse(response, 'state')),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchZipCodeList = (limit, zip, state, city) => (dispatch) => {
  dispatch(showActionLoading());
  getUSZipCodeList(limit, zip, state, city).then(
    response => dispatch(successResponse(response, 'zip')),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchAddressBook = (
  page, limit, name, carrierCode, countryCode, state, city,
  sortColumn, sortType,
) => (dispatch) => {
  const cond = 'list';
  let xname = null;
  let carrier = null;
  let country = null;
  let xstate = null;
  let xcity = null;
  let loadAction = false;
  if (typeof name !== 'undefined') {
    xname = name;
    if (name === '') {
      xname = null;
    }
    loadAction = true;
  }
  if (typeof carrierCode !== 'undefined') {
    carrier = carrierCode;
    if (carrierCode === '') {
      carrier = null;
    }
    loadAction = true;
  }
  if (typeof countryCode !== 'undefined') {
    country = countryCode;
    if (countryCode === '') {
      country = null;
    }
    loadAction = true;
  }
  if (typeof state !== 'undefined') {
    xstate = state;
    if (state === '') {
      xstate = null;
    }
    loadAction = true;
  }
  if (typeof city !== 'undefined') {
    xcity = city;
    if (city === '') {
      xcity = null;
    }
    loadAction = true;
  }
  // if (typeof text !== 'undefined') {
  //   txt = text;
  //   if (text === '') {
  //     txt = null;
  //   }
  //   cond = 'slist';
  //   loadAction = true;
  // }
  if (loadAction) {
    dispatch(showActionLoading());
  } else {
    dispatch(showLoading());
  }
  getAddressBook(
    page, limit, xname, carrier, country, xstate, xcity,
    sortColumn, sortType,
  ).then(
    response => dispatch(successResponse(response, cond)),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchMoreAddressBook = (
  page, limit, name, carrierCode, countryCode, state, city,
  sortColumn, sortType,
) => (dispatch) => {
  let xname = null;
  let carrier = null;
  let country = null;
  let xstate = null;
  let xcity = null;
  if (typeof name !== 'undefined') {
    xname = name;
    if (name === '') {
      xname = null;
    }
  }
  if (typeof carrierCode !== 'undefined') {
    carrier = carrierCode;
    if (carrierCode === '') {
      carrier = null;
    }
  }
  if (typeof countryCode !== 'undefined') {
    country = countryCode;
    if (countryCode === '') {
      country = null;
    }
  }
  if (typeof state !== 'undefined') {
    xstate = state;
    if (state === '') {
      xstate = null;
    }
  }
  if (typeof city !== 'undefined') {
    xcity = city;
    if (city === '') {
      xcity = null;
    }
  }
  dispatch(showActionLoading());
  getAddressBook(
    page, limit, xname, carrier, country, xstate, xcity,
    sortColumn, sortType,
  ).then(
    response => dispatch(successResponse(response, 'morelist')),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchAddressBookDetail = id => (dispatch) => {
  dispatch(showActionLoading());
  requestAddressBookDetails(id).then(
    response => dispatch(successResponse(response, 'detail')),
    err => dispatch(errorResponse(err)),
  );
};

export const addAddressBook = (dataToSubmit, id = null) => (dispatch) => {
  dispatch(showActionLoading());
  postAddressBook(dataToSubmit, id).then(
    response => dispatch(successResponse(response, '')),
    err => dispatch(errorResponse(err, false)),
  );
};

export const removeAddressBook = id => (dispatch) => {
  dispatch(showActionLoading());
  deleteAddressBook(id).then(
    response => dispatch(successResponse(response, 'deletestock')),
    err => dispatch(errorResponse(err, false)),
  );
};
