export const SHOW_LOADING = 'loading/show-loading';

export const HIDE_LOADING = 'loading/hide-loading';

export const SET_CONFIRM = 'loading/set-confirm';

export const SET_LOADING_MSG = 'loading/set-loading-msg';

export const SET_LOAD_TYPE = 'loading/set-load-ident';

export const SHOW_ACTION_LOADING = 'loading/show-acion-loading';

export const HIDE_ACTION_LOADING = 'loading/hide-acion-loading';
