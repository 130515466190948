import { connect } from 'react-redux';
import { menuItems } from 'ui/route/Routes';

import App from './App';

export const mapStateToProps = () => ({
  menuItems,
});

/* export const mapDispatchToProps = dispatch => ({
  onWillMount: () => {
    dispatch(fetchLoggedUser());
  },
}); */

const AppContainer = connect(mapStateToProps)(App);
// const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);

export default AppContainer;
