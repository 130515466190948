import { createSelector } from 'reselect';

export const countriesSelector = state => state.address.list;
export const zipcitystateSelector = state => state.address.zipcitystate;
export const counytryStateSelector = state => state.address.countryState;
export const addressSelector = state => state.address.addressData;
export const addressBookSelector = state => state.address.addressBookData;
export const addressBookDetailSelector = state => state.address.addressBookDetail;
export const addressBookObjSelector = state => state.address.addressBookObj;

export const getCountries = createSelector(
  countriesSelector,
  list => list,
);

export const getZipCityState = createSelector(
  zipcitystateSelector,
  zipcitystate => zipcitystate,
);

export const getCounytryState = createSelector(
  counytryStateSelector,
  countryState => countryState,
);

export const getAddressData = createSelector(
  addressSelector,
  addressData => addressData,
);

export const getAddressBookData = createSelector(
  addressBookSelector,
  addressBookData => addressBookData,
);

export const getAddressBookDetails = createSelector(
  addressBookDetailSelector,
  addressBookDetail => addressBookDetail,
);

export const getAddressBookObj = createSelector(
  addressBookObjSelector,
  addressBookObj => addressBookObj,
);
