const styles = () => {
  const drawerWidth = 172;
  return {
    container: {
      minWidth: `calc(1280px - 72px - ${drawerWidth}px)`,
      paddingLeft: `calc(36px + ${drawerWidth}px)`,
      paddingRight: 36,
      paddingTop: 140,
      '@media (max-width:1240px)': {
        padding: '106px 0 0',
        minWidth: '100%',
        width: '100%',
      },
    },
  };
};

export default styles;
