import { SET_COUNTRIES, UNSET_COUNTRIES, SET_ZIP_CODE_LIST, SET_STATE_DATA, SET_ADDRESS_DATA, SET_ADDRESS_BOOK_DATA, SET_MORE_ADDRESS_BOOK_DATA, SET_ADDRESS_BOOK_DETAILS_DATA, SET_ADRESS_BOOK_OBJECT } from './types';

const initialState = {
  list: JSON.parse(localStorage.getItem('countries')),
  zipcitystate: [],
  countryState: [],
  addressData: {},
  addressBookData: {
    dataObj: {},
    dataArr: [],
  },
  addressBookDetail: {},
  addressBookObj: {},
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_COUNTRIES: {
      return { ...state, list: action.payload.countries };
    }
    case UNSET_COUNTRIES: {
      return action.payload.countries;
    }
    case SET_ZIP_CODE_LIST: {
      return { ...state, zipcitystate: action.payload.zipcitystate };
    }
    case SET_STATE_DATA: {
      return { ...state, countryState: action.payload.countryState };
    }
    case SET_ADDRESS_DATA: {
      return { ...state, addressData: action.payload.addressData };
    }
    case SET_ADDRESS_BOOK_DATA: {
      return Object.assign({}, state, {
        addressBookData: {
          dataObj: action.payload.addressBookData,
          dataArr: action.payload.addressBookData.data,
        },
      });
    }
    case SET_MORE_ADDRESS_BOOK_DATA: {
      const dataArr = state.addressBookData.dataArr.concat(action.payload.addressBookData.data);
      return Object.assign({}, state, {
        addressBookData: {
          dataObj: action.payload.addressBookData,
          dataArr,
        },
      });
    }
    case SET_ADDRESS_BOOK_DETAILS_DATA: {
      return { ...state, addressBookDetail: action.payload.addressBookDetail };
    }
    case SET_ADRESS_BOOK_OBJECT: {
      return { ...state, addressBookObj: action.payload.addressBookObj };
    }
    default: return state;
  }
};

export default reducer;
