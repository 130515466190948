import {
  SET_SALES_DATA,
  SET_ORDERS_DATA,
  SET_SALES_TAB_DATA,
  SET_STOCK_PRODUCTS_DATA,
  SET_VIEW_REPORT_DATA,
  SET_SELLING_PRODUCTS_DATA,
  SET_DASHBOARD_DATA,
  SET_WEEKLY_PRODUCTS_DATA,
  SET_MONTHLY_PRODUCTS_DATA,
  SET_TIME_PERIOD_DATA,
  SET_ORDER_REPORTS_DAILY_DATA,
  SET_ORDER_REPORTS_WEEKLY_DATA,
  SET_ORDER_REPORTS_MONTHLY_DATA,
  SET_ORDER_REPORTS_MONTHLY_DASHBOARD_DATA,
  RESET_ORDER_REPORTS_MONTHLY_DASHBOARD_DATA,
  SET_DAILY_PROPS_DATA,
  SET_PRODUCT_REPORTS_MONTHLY_DATA,
  SET_PRODUCT_REPORTS_WEEKLY_DATA,
  SET_PRODUCT_REPORTS_DAILY_DATA,
  SET_SKU_DATA,
  SET_DAY_DATA,
  SET_COLUMN_SETTINGS,
  SET_EXPORT_URL_DATA,
} from './types';

const initialState = {
  sales: [],
  orders: [],
  tab: 0,
  products: [],
  view: false,
  sellproducts: [],
  dashboard: {},
  weeklyproducts: [],
  monthlyproducts: [],
  timeperiod: {},
  ordaily: {},
  orweekly: {},
  ormonthly: {},
  ormonthlydashboard: {},
  prodmonthly: {},
  prodweekly: {},
  proddaily: {},
  skudata: {},
  dailyprops: {},
  daydata: '',
  reportColumnData: JSON.parse(localStorage.getItem('reportColumnData')),
  exportdata: {},
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_SALES_DATA: {
      return { ...state, sales: action.payload.sales };
    }
    case SET_ORDERS_DATA: {
      return { ...state, orders: action.payload.orders };
    }
    case SET_SALES_TAB_DATA: {
      return { ...state, tab: action.payload.tab };
    }
    case SET_STOCK_PRODUCTS_DATA: {
      return { ...state, products: action.payload.products };
    }
    case SET_VIEW_REPORT_DATA: {
      return { ...state, view: action.payload.view };
    }
    case SET_SELLING_PRODUCTS_DATA: {
      return { ...state, sellproducts: action.payload.sellproducts };
    }
    case SET_DASHBOARD_DATA: {
      return { ...state, dashboard: action.payload.dashboard };
    }
    case SET_WEEKLY_PRODUCTS_DATA: {
      return { ...state, weeklyproducts: action.payload.weeklyproducts };
    }
    case SET_MONTHLY_PRODUCTS_DATA: {
      return { ...state, monthlyproducts: action.payload.monthlyproducts };
    }
    case SET_TIME_PERIOD_DATA: {
      return { ...state, timeperiod: action.payload.timeperiod };
    }
    case SET_ORDER_REPORTS_DAILY_DATA: {
      return { ...state, ordaily: action.payload.ordaily };
    }
    case SET_ORDER_REPORTS_WEEKLY_DATA: {
      return { ...state, orweekly: action.payload.orweekly };
    }
    case SET_ORDER_REPORTS_MONTHLY_DATA: {
      return { ...state, ormonthly: action.payload.ormonthly };
    }
    case SET_ORDER_REPORTS_MONTHLY_DASHBOARD_DATA: {
      return { ...state, ormonthlydashboard: action.payload.ormonthlydashboard };
    }
    case RESET_ORDER_REPORTS_MONTHLY_DASHBOARD_DATA: {
      return { ...state, ormonthlydashboard: action.payload };
    }
    case SET_PRODUCT_REPORTS_MONTHLY_DATA: {
      return { ...state, prodmonthly: action.payload.prodmonthly };
    }
    case SET_PRODUCT_REPORTS_WEEKLY_DATA: {
      return { ...state, prodweekly: action.payload.prodweekly };
    }
    case SET_PRODUCT_REPORTS_DAILY_DATA: {
      return { ...state, proddaily: action.payload.proddaily };
    }
    case SET_DAILY_PROPS_DATA: {
      return { ...state, dailyprops: action.payload.dailyprops };
    }
    case SET_SKU_DATA: {
      return { ...state, skudata: action.payload.skudata };
    }
    case SET_DAY_DATA: {
      return { ...state, daydata: action.payload.data };
    }
    case SET_EXPORT_URL_DATA: {
      return { ...state, exportdata: action.payload.exportdata };
    }
    case SET_COLUMN_SETTINGS: {
      localStorage.setItem('reportColumnData', JSON.stringify(action.payload.reportColumnData));
      return { ...state, reportColumnData: action.payload.reportColumnData };
    }
    default: return state;
  }
};

export default reducer;
