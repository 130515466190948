import axios from 'axios';
import { fuelheaders } from 'state/logged-user/actions';
import { SITE_SERVER } from './misc';

if (process.env.NODE_ENV !== 'development') {
  axios.defaults.withCredentials = true;
}

const getUserProfile = () => axios({
  method: 'GET',
  url: `${SITE_SERVER}/clients`,
  headers: fuelheaders(),
});

export default getUserProfile;

