export const SET_ORDERS_DATA = 'orders/set-orders-data';
export const SET_MORE_ORDERS_DATA = 'orders/set-more-orders-data';
export const SET_ORDERS_ITEM_DATA = 'orders/set-orders-item-data';
export const SET_MORE_ORDERS_ITEM_DATA = 'orders/set-more-orders-item-data';
export const SET_VENDOR_ORDERS_DATA = 'orders/set-vendor-orders-data';
export const SET_MORE_VENDOR_ORDERS_DATA = 'orders/set-more-vendor-orders-data';
export const SET_ORDER_DETAILS_DATA = 'orders/set-order-details-data';
export const RESET_ORDERS_DATA = 'orders/reset-orders-data';
export const SET_RA_DATA = 'orders/set-ra-data';
export const SET_EDIT_DATA = 'orders/set-edit-data';
export const SET_RECIPIENT_DATA = 'orders/set-recipient-data';
export const SET_CARRIER_SERVICE_DATA = 'orders/set-carrier-service-data';
export const SET_ORDER_INFO_DATA = 'orders/set-order-info-data';
export const SET_RETURNED_ORDERS_DATA = 'orders/set-returned-orders-data';
export const SET_MORE_RETURNED_ORDERS_DATA = 'orders/set-more-returned-orders-data';
export const SET_FILTERS_DATA = 'orders/set-filters-data';
export const SET_RECIPIENT_AUTOCOMPLETE_DATA = 'orders/set-recipient-autocomplete-data';
export const RESET_RECIPIENT_AUTOCOMPLETE_DATA = 'orders/reset-recipient-autocomplete-data';
export const SET_IMPORT_INFO_DATA = 'orders/set-import-info-data';
export const SET_IMPORT_ORDERS_DATA = 'orders/set-import-orders-data';
export const SET_SORT_COLUMN = 'orders/set-sort-column';
export const SET_SORT_TYPE = 'orders/set-sort-type';
export const SET_ORDERS_AUTOCOMPLETE_DATA = 'orders/set-orders-autocomplete-data';
export const RESET_ORDERS_AUTOCOMPLETE_DATA = 'orders/reset-orders-autocomplete-data';
export const SET_ORDER_STATUSES_DATA = 'orders/set-order-statuses-data';
export const SET_ORDER_FILE_DATA = 'orders/set-order-file-data';
export const SET_CSV_ORDER_DATA = 'orders/set-csv-order-data';
export const SET_IMPORT_CSV_ORDER_DATA = 'orders/set-import-csv-order-data';
