import {
  SET_SUPPORT_DATA,
  SET_MORE_SUPPORT_DATA,
  RESET_SUPPORT_DATA,
  SET_CHAT_DETAIL_DATA,
  SET_CHAT_MSG_DATA,
  SET_CHAT_MSG_LIST_DATA,
  SET_MORE_CHAT_MSG_LIST_DATA,
} from './types';

const initialState = {
  list: {
    dataObj: {},
    dataArr: [],
  },
  detail: {},
  msglist: {
    dataObj: {},
    dataArr: [],
  },
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_SUPPORT_DATA: {
      return Object.assign({}, state, {
        list: {
          dataObj: action.payload.support,
          dataArr: action.payload.support.data,
        },
      });
    }
    case SET_MORE_SUPPORT_DATA: {
      const dataArr = state.list.dataArr.concat(action.payload.support.data);
      return Object.assign({}, state, {
        list: {
          dataObj: action.payload.support,
          dataArr,
        },
      });
    }
    case RESET_SUPPORT_DATA: {
      return Object.assign({}, state, {
        list: {
          dataObj: {},
          dataArr: [],
        },
      });
    }
    case SET_CHAT_DETAIL_DATA: {
      return { ...state, detail: action.payload.detail };
    }
    case SET_CHAT_MSG_DATA: {
      /* const dataArr = state.msglist.dataArr.concat(action.payload.msg.data); */
      return Object.assign({}, state, {
        msglist: {
          dataObj: state.msglist.dataObj,
          dataArr: [action.payload.msg.data, ...state.msglist.dataArr],
        },
      });
    }
    case SET_CHAT_MSG_LIST_DATA: {
      return Object.assign({}, state, {
        msglist: {
          dataObj: action.payload.msglist,
          dataArr: action.payload.msglist.data,
        },
      });
    }
    case SET_MORE_CHAT_MSG_LIST_DATA: {
      const dataArr = state.msglist.dataArr.concat(action.payload.msglist.data);
      return Object.assign({}, state, {
        msglist: {
          dataObj: action.payload.msglist,
          dataArr,
        },
      });
    }
    default: return state;
  }
};

export default reducer;
