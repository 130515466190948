import {
  OPEN_MODAL,
  CLOSE_MODAL,
  OPEN_ALERT_MODAL,
  CLOSE_ALERT_MODAL,
  OPEN_DRAWER,
  CLOSE_DRAWER,
  OPEN_PAGE,
  RELOAD_COMPONENT,
} from './types';

const initialState = {
  Modal: {
    showModal: false,
    modalKey: '',
    params: {},
  },
  AlertModal: {
    showAlertModal: false,
    modalKey: '',
    params: {},
  },
  Drawer: {
    showDrawer: false,
    params: {},
  },
  Page: {
    params: { el: '' },
  },
  Component: {
    params: { component: '' },
  },
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case OPEN_MODAL:
      return Object.assign({}, state, {
        Modal: {
          showModal: true,
          modalKey: action.modalKey,
          params: action.params,
        },
      });

    case CLOSE_MODAL:
      return Object.assign({}, state, {
        Modal: {
          showModal: false,
          modalKey: action.modalKey,
        },
      });

    case OPEN_ALERT_MODAL:
      return Object.assign({}, state, {
        AlertModal: {
          showAlertModal: true,
          modalKey: action.modalKey,
          params: action.params,
        },
      });

    case CLOSE_ALERT_MODAL:
      return Object.assign({}, state, {
        AlertModal: {
          showAlertModal: false,
          modalKey: action.modalKey,
          params: action.params,
        },
      });

    case OPEN_DRAWER:
      return Object.assign({}, state, {
        Drawer: {
          showDrawer: true,
          params: action.params,
        },
      });

    case CLOSE_DRAWER:
      return Object.assign({}, state, {
        Drawer: {
          showDrawer: false,
        },
      });

    case OPEN_PAGE:
      return Object.assign({}, state, {
        Page: {
          params: action.params,
        },
      });

    case RELOAD_COMPONENT:
      return Object.assign({}, state, {
        Component: {
          params: action.params,
        },
      });

    default:
      return state;
  }
};

export default reducer;
