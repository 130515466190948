import axios from 'axios';
import { fuelheaders } from 'state/logged-user/actions';
import { SITE_SERVER, request } from './misc';

if (process.env.NODE_ENV !== 'development') {
  axios.defaults.withCredentials = true;
}

export const updateUser = (id, dataToSubmit) => axios({
  method: request,
  url: `${SITE_SERVER}/users/${id}`,
  headers: fuelheaders(),
  data: dataToSubmit,
});

export const getStaff = (limit, page) => axios({
  method: 'GET',
  url: `${SITE_SERVER}/users?limit=${limit}&page=${page}`,
  headers: fuelheaders(),
});

export const getStaffById = id => axios({
  method: 'GET',
  url: `${SITE_SERVER}/users/${id}`,
  headers: fuelheaders(),
});

export const addUser = dataToSubmit => axios({
  method: 'POST',
  url: `${SITE_SERVER}/users`,
  headers: fuelheaders(),
  data: dataToSubmit,
});

export const deleteUser = id => axios({
  method: 'DELETE',
  url: `${SITE_SERVER}/users/${id}`,
  headers: fuelheaders(),
});

export const updatClientInfo = dataToSubmit => axios({
  method: request,
  url: `${SITE_SERVER}/clients`,
  headers: fuelheaders(),
  data: dataToSubmit,
});

