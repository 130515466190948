import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import { api } from '@entando/apimanager';
import { routerReducer as router } from '@entando/router';

import loading from './loading/reducer';
import modal from './modal-drawer/reducer';
import loggedUser from './logged-user/reducer';
import loginForm from './login-form/reducer';
import orders from './orders/reducer';
import products from './products/reducer';
import reports from './reports/reducer';
import support from './support/reducer';
import notification from './notification/reducer';
import staff from './staff/reducer';
import role from './role/reducer';
import address from './address/reducer';
import settings from './settings/reducer';

const reducerDef = {
  api,
  form,
  loading,
  modal,
  loggedUser,
  loginForm,
  orders,
  products,
  reports,
  support,
  notification,
  staff,
  role,
  address,
  settings,
  router,
};

// app root reducer
const reducer = combineReducers(reducerDef);

export default reducer;
