export const SET_PRODUCTS_DATA = 'products/set-products-data';
export const SET_MORE_PRODUCTS_DATA = 'products/set-more-products-data';
export const SET_PRODUCT_DETAILS_DATA = 'products/set-product-details-data';
export const RESET_PRODUCT_DETAILS_DATA = 'products/reset-product-details-data';
export const SET_PRODUCT_TYPES_DATA = 'products/set-product-types-data';
export const SET_PRODUCT_INFO_DATA = 'products/set-product-info-data';
export const RESET_PRODUCTS_DATA = 'products/reset-products-data';
export const SET_VENDOR_DATA = 'products/set-vendor-data';
export const SET_VENDOR_ORDERS_DATA = 'products/set-vendor-orders-data';
export const SET_VENDOR_AUTOCOMPLETE_DATA = 'products/set-vendor-autocomplete-data';
export const RESET_VENDOR_AUTOCOMPLETE_DATA = 'products/reset-vendor-autocomplete-data';
export const SET_MANUFACTURER_DATA = 'products/set-manufacturer-data';
export const SET_MANUFACTURER_AUTOCOMPLETE_DATA = 'products/set-manufacturer-autocomplete-data';
export const RESET_MANUFACTURER_AUTOCOMPLETE_DATA = 'products/reset-manufacturer-autocomplete-data';
export const SET_PRODUCT_AUTOCOMPLETE_DATA = 'products/set-product-autocomplete-data';
export const RESET_PRODUCT_AUTOCOMPLETE_DATA = 'products/reset-product-autocomplete-data';
export const SET_FILTERS_DATA = 'products/set-filters-data';
export const SET_IMPORT_INFO_DATA = 'products/set-import-info-data';
export const SET_IMPORT_PRODUCTS_DATA = 'products/set-import-products-data';
export const SET_SALES_ORDER_DATA = 'products/set-sales-order-data';
export const SET_MORE_SALES_ORDER_DATA = 'products/set-more-sales-order-data';
export const SET_SALES_ORDER_PAGE_DATA = 'products/set-sales-order-page-data';
export const SET_STOCK_ALERT_DATA = 'products/set-stock-alert-data';
export const SET_MORE_STOCK_ALERT_DATA = 'products/set-more-stock-alert-data';
export const ADD_STOCK_ALERT_DATA = 'products/add-stock-alert-data';
export const SET_STOCK_RECIPIENT_DATA = 'products/set-stock-recipient-data';
export const ADD_STOCK_RECIPIENT_DATA = 'products/add-stock-recipient-data';
export const SET_RECEIPTS_DATA = 'products/add-receipts-data';
export const SET_MORE_RECEIPTS_DATA = 'products/add-more-receipts-data';
export const SET_FREIGHT_AUTOCOMPLETE_DATA = 'products/set-freight-autocomplete-data';
export const RESET_FREIGHT_AUTOCOMPLETE_DATA = 'products/reset-freight-autocomplete-data';
export const SET_FREIGHT_DATA = 'products/set-freight-data';
export const SET_SUPPLY_RECEIPT_DETAILS_DATA = 'products/set-supply-receipt-details-data';
export const RESET_SUPPLY_RECEIPT_DETAILS_DATA = 'products/reset-supply-receipt-details-data';
export const SET_STOCK_ALERT_SETTINGS_DATA = 'products/set-stock-alert-settings-data';
export const SET_PRODUCTS_FILE_DATA = 'products/set-products-file-data';
export const SET_CSV_PRODUCT_DATA = 'products/set-csv-product-data';
export const SET_IMPORT_CSV_PRODUCT_DATA = 'products/set-import-csv-product-data';
