import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';


const SPACING_UNIT = 16;

const theme = createMuiTheme({
  palette: {
    common: {
      black: '#1f1c1c',
      white: '#fff',
    },
    background: {
      paper: '#f6f7f7',
      default: '#f6f7f7',
      border: '#d9d7d7',
    },
    primary: {
      light: '#949db2',
      lightmobile: '#767F91',
      main: '#446bb0',
      dark: '#446bb0',
      contrastText: '#fff',
    },
    secondary: {
      light: '#fff',
      main: '#09B0ee',
      dark: '#09B0ee',
      contrastText: '#fff',
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
    text: {
      btnlink: '#E07E7E',
      gray20: '#333',
      cancel: '#8B8A8A',
      primary: '#5d5b5b',
      secondary: '#09B0ee',
      disabled: '#000',
      hint: '#000',
      placeholder: '#96a3af',
      dot: '#ff5b34',
    },
    header: {
      primary: '#96a3af',
    },
    spacing: {
      unit: SPACING_UNIT,
    },
  },
  typography: {
    useNextVariants: true,
  },
  classes: {
    shell: {
      root: {
        flexGrow: 1,
        minHeight: '100vh',
      },
      headToolbar: {
        paddingLeft: SPACING_UNIT,
        paddingRight: SPACING_UNIT,
      },
    },
    flex: {
      flexGrow: 1,
    },
    wholebtmcont: {
      flex: 1,
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
    paper: {
      padding: SPACING_UNIT,
    },
    avatar: {
      margin: 0,
      boxShadow: '0 3px 8px rgba(217, 215, 215, 0.95)',
    },
    modalBtn: {
      height: 30,
      minHeight: 30,
      minWidth: 'auto',
      borderRadius: 100,
      boxShadow: 'none',
      textTransform: 'capitalize',
      padding: '0 34px',
      '&:hover': {
        boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
      },
      '@media (max-width:1240px)': {
        padding: '0 18px',
        height: 36,
        minHeight: 36,
        fontSize: 12,
        '&.confirm': {
          width: 116,
        },
        '&.done': {
          width: 85,
        },
      },
    },
    outlinedBtn: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    hide: {
      display: 'none',
    },
    close: {
      backgroundColor: '#fff',
      color: '#949db2',
      fontSize: 12,
      textTransform: 'capitalize',
      cursor: 'pointer',
      display: 'inline-block',
      width: 74,
      minWidth: '74px',
      height: 30,
      minHeight: '30px',
      padding: 0,
      boxShadow: 'none',
      borderRadius: 100,
      border: '1px solid #C5D1E0',
      marginLeft: 10,
      '& svg': {
        stroke: '#09B0ee',
        marginLeft: 7,
        verticalAlign: 'middle',
      },
      '&:hover': {
        backgroundColor: '#fff',
        boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
      },
      '@media (max-width:1240px)': {
        display: 'none',
      },
    },
    closemobile: {
      display: 'none',
      '@media (max-width:1240px)': {
        display: 'inline-block',
      },
    },
    closev2: {
      width: 15,
      height: 15,
      stroke: '#09B0ee',
    },
    closev3: {
      stroke: '#09B0ee',
    },
    cancelbtn: {
      backgroundColor: '#fff',
      color: '#09B0EE',
      fontSize: 12,
      textTransform: 'capitalize',
      cursor: 'pointer',
      display: 'inline-block',
      minWidth: '74px',
      padding: '0 20px',
      height: 30,
      minHeight: '30px',
      boxShadow: 'none',
      borderRadius: 100,
      border: '1px solid #C5D1E0',
      marginLeft: 10,
      '&:hover': {
        backgroundColor: '#fff',
        boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
      },
    },
    medium: {
      fontWeight: 500,
    },
    regular: {
      fontWeight: 400,
    },
    iconbtn: {
      padding: 12,
      '&:hover': {
        backgroundColor: '#E8EAEE',
      },
    },
    addbtn: {
      padding: '14px 12px',
    },
    delicon: {
      padding: '13px 15px',
    },
    error_label: {
      color: '#F44336',
      display: 'block',
      fontSize: 15,
    },
    loaderWrapper: {
      position: 'relative',
      width: '100%',
      height: '100%',
    },
    drawerLoadingWrapper: {
      padding: '20% 0 0 0',
    },
    buttonProgress: {
      color: '#446bb0',
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    loadMoreProgress: {
      left: 'inherit',
      right: '12%',
    },
    loadMoreWrapper: {
      position: 'relative',
      display: 'inline-block',
    },
    loadMoreBtn: {
      height: 34,
      minHeight: 34,
      minWidth: 'auto',
      borderRadius: 100,
      boxShadow: 'none',
      fontSize: 14,
      fontWeight: 500,
      textTransform: 'capitalize',
      padding: '0 78px',
      '&:hover': {
        boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
      },
    },
    linkinactive: {
      color: '#d3d3d3',
      '& span': {
        color: 'inherit !important',
      },
    },
    defaultcursor: {
      cursor: 'default',
    },
    gradg11: {
      background: 'rgba(255,172,124,1)',
      filter: 'progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffac7c", endColorstr="#f48546", GradientType=1 )',
    },
    gradg12: {
      background: '-moz-linear-gradient(left, rgba(255,172,124,1) 0%, rgba(244,133,70,1) 100%)',
    },
    gradg13: {
      background: '-webkit-gradient(left top, right top, color-stop(0%, rgba(255,172,124,1)), color-stop(100%, rgba(244,133,70,1)))',
    },
    gradg14: {
      background: '-webkit-linear-gradient(left, rgba(255,172,124,1) 0%, rgba(244,133,70,1) 100%)',
    },
    gradg15: {
      background: '-o-linear-gradient(left, rgba(255,172,124,1) 0%, rgba(244,133,70,1) 100%)',
    },
    gradg16: {
      background: '-ms-linear-gradient(left, rgba(255,172,124,1) 0%, rgba(244,133,70,1) 100%)',
    },
    gradg17: {
      background: 'linear-gradient(to right, rgba(255,172,124,1) 0%, rgba(244,133,70,1) 100%)',
    },
    gradg21: {
      background: 'rgba(105,209,169,1)',
      filter: 'progid:DXImageTransform.Microsoft.gradient( startColorstr="#69d1a9", endColorstr="#15dbbd", GradientType=1 )',
    },
    gradg22: {
      background: '-moz-linear-gradient(left, rgba(105,209,169,1) 0%, rgba(21,219,189,1) 100%)',
    },
    gradg23: {
      background: '-webkit-gradient(left top, right top, color-stop(0%, rgba(105,209,169,1)), color-stop(100%, rgba(21,219,189,1)))',
    },
    gradg24: {
      background: '-webkit-linear-gradient(left, rgba(105,209,169,1) 0%, rgba(21,219,189,1) 100%)',
    },
    gradg25: {
      background: '-o-linear-gradient(left, rgba(105,209,169,1) 0%, rgba(21,219,189,1) 100%)',
    },
    gradg26: {
      background: '-ms-linear-gradient(left, rgba(105,209,169,1) 0%, rgba(21,219,189,1) 100%)',
    },
    gradg27: {
      background: 'linear-gradient(to right, rgba(105,209,169,1) 0%, rgba(21,219,189,1) 100%)',
    },
    gradg31: {
      background: 'rgba(255,228,116,1)',
      filter: 'progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffe474", endColorstr="#f7d134", GradientType=1 )',
    },
    gradg32: {
      background: '-moz-linear-gradient(left, rgba(255,228,116,1) 0%, rgba(247,209,52,1) 100%)',
    },
    gradg33: {
      background: '-webkit-gradient(left top, right top, color-stop(0%, rgba(255,228,116,1)), color-stop(100%, rgba(247,209,52,1)))',
    },
    gradg34: {
      background: '-webkit-linear-gradient(left, rgba(255,228,116,1) 0%, rgba(247,209,52,1) 100%)',
    },
    gradg35: {
      background: '-o-linear-gradient(left, rgba(255,228,116,1) 0%, rgba(247,209,52,1) 100%)',
    },
    gradg36: {
      background: '-ms-linear-gradient(left, rgba(255,228,116,1) 0%, rgba(247,209,52,1) 100%)',
    },
    gradg37: {
      background: 'linear-gradient(to right, rgba(255,228,116,1) 0%, rgba(247,209,52,1) 100%)',
    },
    gradg41: {
      background: 'rgba(106,213,255,1)',
      filter: 'progid:DXImageTransform.Microsoft.gradient( startColorstr="#6ad5ff", endColorstr="#3fc7fd", GradientType=1 )',
    },
    gradg42: {
      background: '-moz-linear-gradient(left, rgba(106,213,255,1) 0%, rgba(63,199,253,1) 100%)',
    },
    gradg43: {
      background: '-webkit-gradient(left top, right top, color-stop(0%, rgba(106,213,255,1)), color-stop(100%, rgba(63,199,253,1)))',
    },
    gradg44: {
      background: '-webkit-linear-gradient(left, rgba(106,213,255,1) 0%, rgba(63,199,253,1) 100%)',
    },
    gradg45: {
      background: '-o-linear-gradient(left, rgba(106,213,255,1) 0%, rgba(63,199,253,1) 100%)',
    },
    gradg46: {
      background: '-ms-linear-gradient(left, rgba(106,213,255,1) 0%, rgba(63,199,253,1) 100%)',
    },
    gradg47: {
      background: 'linear-gradient(to right, rgba(106,213,255,1) 0%, rgba(63,199,253,1) 100%)',
    },
    gradg51: {
      background: 'rgba(255,214,110,1)',
      filter: 'filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffe28c", endColorstr="#facb49", GradientType=1 )',
    },
    gradg52: {
      background: '-moz-linear-gradient(left, rgba(255,214,110,1) 0%, rgba(255,197,38,1) 100%)',
    },
    gradg53: {
      background: '-webkit-gradient(left top, right top, color-stop(0%, rgba(255,214,110,1)), color-stop(100%, rgba(255,197,38,1)))',
    },
    gradg54: {
      background: '-webkit-linear-gradient(left, rgba(255,214,110,1) 0%, rgba(255,197,38,1) 100%)',
    },
    gradg55: {
      background: '-o-linear-gradient(left, rgba(255,214,110,1) 0%, rgba(255,197,38,1) 100%)',
    },
    gradg56: {
      background: '-o-linear-gradient(left, rgba(255,214,110,1) 0%, rgba(255,197,38,1) 100%)',
    },
    gradg57: {
      background: 'linear-gradient(to right, rgba(255,214,110,1) 0%, rgba(255,197,38,1) 100%)',
    },
    gradg61: {
      background: 'rgba(68,107,176,1)',
      filter: 'progid:DXImageTransform.Microsoft.gradient( startColorstr="#446bb0", endColorstr="#09b0ee", GradientType=1 )',
    },
    gradg62: {
      background: '-moz-linear-gradient(left, rgba(68,107,176,1) 0%, rgba(9,176,238,1) 100%)',
    },
    gradg63: {
      background: '-webkit-gradient(left top, right top, color-stop(0%, rgba(68,107,176,1)), color-stop(100%, rgba(9,176,238,1)))',
    },
    gradg64: {
      background: '-webkit-linear-gradient(left, rgba(68,107,176,1) 0%, rgba(9,176,238,1) 100%)',
    },
    gradg65: {
      background: '-o-linear-gradient(left, rgba(68,107,176,1) 0%, rgba(9,176,238,1) 100%)',
    },
    gradg66: {
      background: '-ms-linear-gradient(left, rgba(68,107,176,1) 0%, rgba(9,176,238,1) 100%)',
    },
    gradg67: {
      background: 'linear-gradient(to right, rgba(68,107,176,1) 0%, rgba(9,176,238,1) 100%)',
    },
  },
});

function withTheme(Component) {
  function WithTheme(props) {
    // MuiThemeProvider makes the theme available down the React tree
    // thanks to React context.
    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Component {...props} />
      </MuiThemeProvider>
    );
  }
  return WithTheme;
}

export default withTheme;
