import { createSelector } from 'reselect';

export const eventsSelector = state => state.settings.calevents;
export const eventsNotifSelector = state => state.settings.caleventsnotif;
export const timezoneSelector = state => state.settings.timezones;

export const getEvents = createSelector(
  eventsSelector,
  calevents => calevents,
);

export const getEventsNotif = createSelector(
  eventsNotifSelector,
  caleventsnotif => caleventsnotif,
);

export const getTimezone = createSelector(
  timezoneSelector,
  timezones => timezones,
);
