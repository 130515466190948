import { routerConfig } from '@entando/router';

import store from 'state/store';

export const ROUTE_DASHBOARD = 'dashboard';
export const ROUTE_ORDERS = 'orders';
export const ROUTE_PENDING_ORDERS = 'pending-orders';
export const ROUTE_SHIPPED_ORDERS = 'shipped-orders';
export const ROUTE_RETURNED_ORDERS = 'returned-orders';
export const ROUTE_RETURN_AUTHORIZATION = 'return-authorization';
export const ROUTE_THIRD_PARTY = 'third-party';
export const ROUTE_PRODUCTS = 'products';
export const ROUTE_PRODUCT_DETAIL = 'product-detail';
export const ROUTE_VENDOR_ORDERS = 'products-vendor-orders';
export const ROUTE_SUPPLY_RECEIPTS = 'supply-receipts';
export const ROUTE_TOP_SELLING_PRODUCTS = 'top-selling-products';
export const ROUTE_LOW_IN_STOCKS = 'products-low-in-stocks';
export const ROUTE_OUT_OF_STOCKS = 'products-out-of-stocks';
export const ROUTE_STOCK_ALERT_SETTINGS = 'stock-alert-settings';
export const ROUTE_REPORTS = 'reports';
export const ROUTE_INVENTORY_REPORTS = 'inventory-reports';
export const ROUTE_RETURNS_REPORTS = 'returns-reports';
export const ROUTE_SUPPLY_RECEIPT_REPORTS = 'supply-receipts-reports';
export const ROUTE_SALES_BY_SKU = 'sales-by-sku';
export const ROUTE_SUPPORT = 'support';
export const ROUTE_SUPPORT_CHAT = 'support-chat';
export const ROUTE_BILLING_SUPPORT = 'billing-support';
export const ROUTE_BILLING_SUPPORT_CHAT = 'billing-support-chat';
export const ROUTE_TECH_SUPPORT = 'tech-support';
export const ROUTE_TECH_SUPPORT_CHAT = 'tech-support-chat';
export const ROUTE_SPECIAL_PROJECTS = 'special-projects';
export const ROUTE_HOLIDAYS = 'holidays';
export const ROUTE_FAQ = 'faq';
export const ROUTE_PROFILE = 'profile';
export const ROUTE_ADDRESS_BOOK = 'address-book';
export const ROUTE_MANAGE_ROLES = 'manage-roles';
export const ROUTE_ACCOUNT_INFO = 'account-info';
export const ROUTE_NOTIFICATION = 'notification';
export const ROUTE_TIMEZONE = 'timezone';
export const ROUTE_STAFF = 'staff';
export const ROUTE_LOGIN = 'login';
export const ROUTE_ADMINLOGIN = 'admin-login';
export const ROUTE_RESETPASS = 'reset';
export const ROUTE_RESETLINK = 'reset-link';
export const ROUTE_WELCOMELINK = 'welcome';
export const ROUTE_OPERATIONS = 'ips-operations';
export const ROUTE_404 = 'notFound';

export const PATH_DASHBOARD = '/';
export const PATH_ORDERS = '/orders';
export const PATH_PENDING_ORDERS = '/pending-orders';
export const PATH_SHIPPED_ORDERS = '/shipped-orders';
export const PATH_RETURNED_ORDERS = '/returned-orders';
export const PATH_RETURN_AUTHORIZATION = '/return-authorization';
export const PATH_THIRD_PARTY = '/third-party';
export const PATH_PRODUCTS = '/products';
export const PATH_PRODUCT_DETAIL = '/products/:id';
export const PATH_VENDOR_ORDERS = '/products-vendor-orders';
export const PATH_SUPPLY_RECEIPTS = '/supply-receipts';
export const PATH_TOP_SELLING_PRODUCTS = '/top-selling-products';
export const PATH_LOW_IN_STOCKS = '/products-low-in-stocks';
export const PATH_OUT_OF_STOCKS = '/products-out-of-stocks';
export const PATH_STOCK_ALERT_SETTINGS = '/stock-alert-settings';
export const PATH_REPORTS = '/reports';
export const PATH_INVENTORY_REPORTS = '/inventory-reports';
export const PATH_RETURNS_REPORTS = '/returns-reports';
export const PATH_SUPPLY_RECEIPT_REPORTS = '/supply-receipts-reports';
export const PATH_SALES_BY_SKU = '/sales-by-sku';
export const PATH_SUPPORT = '/support';
export const PATH_SUPPORT_CHAT = '/support/chat/:id';
export const PATH_BILLING_SUPPORT = '/billing-support';
export const PATH_BILLING_SUPPORT_CHAT = '/billing-support/chat/:id';
export const PATH_TECH_SUPPORT = '/tech-support';
export const PATH_TECH_SUPPORT_CHAT = '/tech-support/chat/:id';
export const PATH_SPECIAL_PROJECTS = '/special-projects';
export const PATH_HOLIDAYS = '/holidays';
export const PATH_FAQ = '/faq';
export const PATH_PROFILE = '/profile';
export const PATH_ADDRESS_BOOK = '/address-book';
export const PATH_MANAGE_ROLES = '/manage-roles';
export const PATH_ACCOUNT_INFO = '/account-info';
export const PATH_NOTIFICATION = '/notification';
export const PATH_TIMEZONE = '/timezone';
export const PATH_STAFF = '/staff';
export const PATH_LOGIN = '/login';
export const PATH_ADMINLOGIN = '/admin-login';
export const PATH_RESETPASS = '/reset';
export const PATH_RESETLINK = '/reset-link';
export const PATH_WELCOMELINK = '/welcome';
export const PATH_OPERATIONS = '/ips-operations';
export const PATH_404 = '/notFound';

export const routes = [
  { name: ROUTE_DASHBOARD, path: PATH_DASHBOARD },
  { name: ROUTE_ORDERS, path: PATH_ORDERS },
  { name: ROUTE_PENDING_ORDERS, path: PATH_PENDING_ORDERS },
  { name: ROUTE_SHIPPED_ORDERS, path: PATH_SHIPPED_ORDERS },
  { name: ROUTE_RETURNED_ORDERS, path: PATH_RETURNED_ORDERS },
  { name: ROUTE_RETURN_AUTHORIZATION, path: PATH_RETURN_AUTHORIZATION },
  { name: ROUTE_THIRD_PARTY, path: PATH_THIRD_PARTY },
  { name: ROUTE_PRODUCTS, path: PATH_PRODUCTS },
  { name: ROUTE_PRODUCT_DETAIL, path: PATH_PRODUCT_DETAIL },
  { name: ROUTE_VENDOR_ORDERS, path: PATH_VENDOR_ORDERS },
  { name: ROUTE_SUPPLY_RECEIPTS, path: PATH_SUPPLY_RECEIPTS },
  { name: ROUTE_TOP_SELLING_PRODUCTS, path: PATH_TOP_SELLING_PRODUCTS },
  { name: ROUTE_LOW_IN_STOCKS, path: PATH_LOW_IN_STOCKS },
  { name: ROUTE_OUT_OF_STOCKS, path: PATH_OUT_OF_STOCKS },
  { name: ROUTE_STOCK_ALERT_SETTINGS, path: PATH_STOCK_ALERT_SETTINGS },
  { name: ROUTE_REPORTS, path: PATH_REPORTS },
  { name: ROUTE_INVENTORY_REPORTS, path: PATH_INVENTORY_REPORTS },
  { name: ROUTE_RETURNS_REPORTS, path: PATH_RETURNS_REPORTS },
  { name: ROUTE_SUPPLY_RECEIPT_REPORTS, path: PATH_SUPPLY_RECEIPT_REPORTS },
  { name: ROUTE_SALES_BY_SKU, path: PATH_SALES_BY_SKU },
  { name: ROUTE_SUPPORT, path: PATH_SUPPORT },
  { name: ROUTE_SUPPORT_CHAT, path: PATH_SUPPORT_CHAT },
  { name: ROUTE_BILLING_SUPPORT, path: PATH_BILLING_SUPPORT },
  { name: ROUTE_BILLING_SUPPORT_CHAT, path: PATH_BILLING_SUPPORT_CHAT },
  { name: ROUTE_TECH_SUPPORT, path: PATH_TECH_SUPPORT },
  { name: ROUTE_TECH_SUPPORT_CHAT, path: PATH_TECH_SUPPORT_CHAT },
  { name: ROUTE_SPECIAL_PROJECTS, path: PATH_SPECIAL_PROJECTS },
  { name: ROUTE_HOLIDAYS, path: PATH_HOLIDAYS },
  { name: ROUTE_FAQ, path: PATH_FAQ },
  { name: ROUTE_PROFILE, path: PATH_PROFILE },
  { name: ROUTE_ADDRESS_BOOK, path: PATH_ADDRESS_BOOK },
  { name: ROUTE_MANAGE_ROLES, path: PATH_MANAGE_ROLES },
  { name: ROUTE_ACCOUNT_INFO, path: PATH_ACCOUNT_INFO },
  { name: ROUTE_NOTIFICATION, path: PATH_NOTIFICATION },
  { name: ROUTE_TIMEZONE, path: PATH_TIMEZONE },
  { name: ROUTE_STAFF, path: PATH_STAFF },
  { name: ROUTE_LOGIN, path: PATH_LOGIN },
  { name: ROUTE_ADMINLOGIN, path: PATH_ADMINLOGIN },
  { name: ROUTE_OPERATIONS, path: PATH_OPERATIONS },
  { name: ROUTE_RESETPASS, path: PATH_RESETPASS },
  { name: ROUTE_RESETLINK, path: PATH_RESETLINK },
  { name: ROUTE_WELCOMELINK, path: PATH_WELCOMELINK },
];

export const matchRoute = (theRoutes, url) => {
  for (let i = 0; i < theRoutes.length; i += 1) {
    const route = theRoutes[i];
    const match = route.re.exec(url);
    if (match) {
      const params = {};
      for (let j = 0; j < route.keys.length; j += 1) {
        params[route.keys[j].name] = match[j + 1];
      }
      return { route, params };
    }
  }
  return null;
};


routerConfig(
  store,
  {
    mode: 'browser',
    routes,
    notFoundRoute: { name: ROUTE_404, path: '/404' },
  },
);
