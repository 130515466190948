import {
  OPEN_MODAL,
  CLOSE_MODAL,
  OPEN_ALERT_MODAL,
  CLOSE_ALERT_MODAL,
  OPEN_DRAWER,
  CLOSE_DRAWER,
  OPEN_PAGE,
  RELOAD_COMPONENT,
} from './types';

export function openModal(obj) {
  return {
    type: OPEN_MODAL,
    modalKey: obj.modalKey,
    params: obj.params,
  };
}

export function closeModal(obj) {
  return {
    type: CLOSE_MODAL,
    modalKey: obj.modalKey,
  };
}

export function openAlertModal(obj) {
  return {
    type: OPEN_ALERT_MODAL,
    modalKey: obj.modalKey,
    params: obj.params,
  };
}

export function closeAlertModal(obj) {
  return {
    type: CLOSE_ALERT_MODAL,
    modalKey: obj.modalKey,
    params: obj.params,
  };
}

export function openDrawer(obj) {
  return {
    type: OPEN_DRAWER,
    params: obj.params,
  };
}

export function closeDrawer() {
  return {
    type: CLOSE_DRAWER,
  };
}

export function openPage(obj) {
  return {
    type: OPEN_PAGE,
    params: obj.params,
  };
}

export function reloadComponent(obj) {
  return {
    type: RELOAD_COMPONENT,
    params: obj.params,
  };
}
