import axios from 'axios';
import { fuelheaders } from 'state/logged-user/actions';
import { SITE_SERVER } from './misc';

if (process.env.NODE_ENV !== 'development') {
  axios.defaults.withCredentials = true;
}

export const getCountries = () => axios({
  method: 'GET',
  url: `${SITE_SERVER}/address/countries`,
  headers: fuelheaders(),
});

export const getUSState = () => axios({
  method: 'GET',
  url: `${SITE_SERVER}/address/states`,
  headers: fuelheaders(),
});

export const getUSZipCodeList = (limit, zip, state = '', city = '') => axios({
  method: 'GET',
  url: `${SITE_SERVER}/autocomplete/address/zips?limit=${limit}&zip=${zip}&state=${state}${city !== '' ? `&city=${city}` : ''}`,
  headers: fuelheaders(),
});
